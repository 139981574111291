import { StandardFonts, PDFDocument, degrees, rgb, PDFNumber, ParseSpeeds } from 'pdf-lib';
import Matrix from './Matrix';
import statuses from './status_list';
import fontkit from '@pdf-lib/fontkit';
const convertColor = (hex) => {
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return rgb(r / 255, g / 255, b / 255);
};
export const findPage = (pdfInfos, posY) => {
    const totalHeight = pdfInfos.reduce((acc, { infos: { boxWidth, boxHeight, isRotated } }) => (acc += isRotated ? boxWidth : boxHeight), 0);
    const actualPos = posY * totalHeight;
    let previousPagesHeight = 0;
    let currentPage = 0;
    let found = undefined;
    while (!found && currentPage < pdfInfos.length) {
        const page = pdfInfos[currentPage];
        const { infos: { boxWidth, boxHeight, isRotated } } = page;
        const nextPageHeight = previousPagesHeight + (isRotated ? boxWidth : boxHeight);
        if (actualPos > nextPageHeight) {
            previousPagesHeight = nextPageHeight;
            currentPage++;
        }
        else {
            // position is in this page
            const page = pdfInfos[currentPage];
            const { infos: { boxWidth, boxHeight, isRotated } } = page;
            found = {
                page: pdfInfos[currentPage],
                yPage: (actualPos - previousPagesHeight) / (isRotated ? boxWidth : boxHeight)
            };
        }
    }
    return found;
};
const findPositionInPage = (page, posX, yPage, widthMax) => {
    const { infos: { boxLeft, boxWidth, boxBottom, boxHeight, isRotated } } = page;
    const deltaWidth = Math.round((widthMax - (isRotated ? boxHeight : boxWidth)) / 2);
    return {
        circleX: posX * widthMax + (isRotated ? boxBottom : boxLeft) - deltaWidth,
        circleY: (1 - yPage) * (isRotated ? boxWidth : boxHeight) + (isRotated ? boxLeft : boxBottom)
    };
};
/**
 * Convert and index to an alaphabetic letter or string (0 -> a, 1 -> b, c, d, e ... aa, ab, ac)
 */
export const indexChar = (index, postfix = '') => {
    const alphabetMax = 26;
    return index < alphabetMax
        ? String.fromCharCode(97 + index) + postfix
        : indexChar(Math.floor(index / alphabetMax) - 1, indexChar(index % alphabetMax));
};
const rotate = (boxWidth, boxHeight, x, y, angle) => {
    if (angle === 270 || angle === -270) {
        return { x: y, y: boxHeight - x };
    }
    if (angle === 90 || angle === -90) {
        return { x: boxWidth - y, y: x };
    }
    return { x, y };
};
const textSize = 8;
const circleSize = 10;
/**
 * Convert pdf array to array of values
 * @param pdfArray is the pdfArray to convert
 * @return converted PDF array
 */
const toArray = (pdfArray) => {
    let result = [];
    for (let i = 0; i < pdfArray.size(); i++) {
        const pdfObject = pdfArray.get(i);
        if (pdfObject instanceof PDFNumber) {
            result.push(pdfObject.value());
        }
    }
    return result;
};
/**
 * @param plan is the document to place positions into
 * @param positions contains positions
 * @returns plan with added positions
 */
export const addPositions = async (plan, positions = [], speedUp = false, font) => {
    const pdfDoc = await PDFDocument.load(plan, { parseSpeed: speedUp ? ParseSpeeds.Fastest : ParseSpeeds.Slow });
    if (pdfDoc && !pdfDoc.isEncrypted) {
        const pdfPages = pdfDoc.getPages();
        let pdfInfos = [];
        let widthMax = 0;
        for (let page of pdfPages) {
            const rotate = page.getRotation();
            let rotation = rotate && rotate.angle ? rotate.angle : 0;
            const cropBox = page.node.CropBox() || page.node.MediaBox();
            const [boxLeft, boxBottom, boxRight, boxTop] = toArray(cropBox);
            const boxWidth = boxRight - boxLeft;
            const boxHeight = boxTop - boxBottom;
            const isRotated = [270, -270, 90, -90].includes(rotation);
            widthMax = Math.max(widthMax, isRotated ? boxHeight : boxWidth);
            pdfInfos.push({
                infos: {
                    boxLeft,
                    boxBottom,
                    boxRight,
                    boxTop,
                    boxWidth,
                    boxHeight,
                    rotation,
                    isRotated
                },
                page,
                positions: [],
                matrix: new Matrix(boxWidth, boxHeight, boxLeft, boxBottom, isRotated)
            });
        }
        let treatedPosition = [];
        for (let position of positions) {
            const alreadyExistingRemarks = treatedPosition.filter(cPos => cPos.nameBeforeRewrite === position.name);
            let positionDisplayName = position.name;
            if (alreadyExistingRemarks.length > 0) {
                treatedPosition = [
                    ...treatedPosition.filter(cPos => cPos.nameBeforeRewrite !== position.name),
                    ...alreadyExistingRemarks.map((pos, idx) => ({
                        ...pos,
                        name: `${position.name}${indexChar(idx)}`,
                        nameBeforeRewrite: position.name
                    }))
                ];
                positionDisplayName = `${position.name}${indexChar(alreadyExistingRemarks.length)}`;
            }
            treatedPosition.push({
                ...position,
                name: positionDisplayName,
                nameBeforeRewrite: position.name,
                color: convertColor((statuses.find(({ number }) => number === position.status) || statuses[0]).color)
            });
        }
        for (let pos of treatedPosition) {
            const { y, x } = pos;
            const found = findPage(pdfInfos, y);
            if (found) {
                const { page, yPage } = found;
                const positionInPage = findPositionInPage(page, x, yPage, widthMax);
                let coloredPosition = page.positions;
                page.matrix.placeMarkersOnMatrix(positionInPage.circleX, positionInPage.circleY);
                coloredPosition.push({
                    ...pos,
                    ...positionInPage
                });
                page.positions = coloredPosition;
            }
        }
        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit);
        const pdfFont = font ? await pdfDoc.embedFont(font) : await pdfDoc.embedStandardFont(StandardFonts.Helvetica);
        for (let { matrix, positions, page, infos: { rotation, boxHeight, boxWidth } } of pdfInfos) {
            for (let { circleX, circleY, name, color } of positions) {
                let currentTextSize = textSize;
                let textWidth = pdfFont.widthOfTextAtSize(name, currentTextSize);
                if (textWidth > circleSize * 2 - 3) {
                    currentTextSize = Math.floor((circleSize * 2 * textSize) / textWidth);
                    textWidth = pdfFont.widthOfTextAtSize(name, currentTextSize);
                }
                const textHeight = pdfFont.heightAtSize(currentTextSize);
                const { newPos: { posX, posY }, overlap } = matrix.getMarkerPosition(circleX, circleY);
                const textX = posX - textWidth / 2;
                const textY = posY - textHeight / 4;
                if (overlap) {
                    const littleDiskCoord = rotate(boxWidth, boxHeight, circleX, circleY, rotation);
                    const pos = rotate(boxWidth, boxHeight, posX, posY, rotation);
                    page.drawLine({
                        start: littleDiskCoord,
                        end: pos,
                        thickness: 1,
                        lineCap: undefined,
                        color: convertColor('168dae')
                    });
                    page.drawCircle({
                        ...littleDiskCoord,
                        size: 1,
                        color: convertColor('168eae') //convertRgbColor(22, 142, 174)
                    });
                }
                page.drawCircle({
                    ...rotate(boxWidth, boxHeight, posX, posY, rotation),
                    size: circleSize + 3,
                    color: convertColor('ffffff')
                });
                page.drawCircle({
                    ...rotate(boxWidth, boxHeight, posX, posY, rotation),
                    size: circleSize + 1.5,
                    color: convertColor('424749')
                });
                page.drawCircle({
                    ...rotate(boxWidth, boxHeight, posX, posY, rotation),
                    size: circleSize,
                    color
                });
                page.drawText(name, {
                    ...rotate(boxWidth, boxHeight, textX, textY, rotation),
                    rotate: degrees(rotation),
                    size: currentTextSize,
                    font: pdfFont,
                    color: convertColor('ffffff')
                });
            }
        }
        return pdfDoc.save({ useObjectStreams: false, objectsPerTick: speedUp ? Infinity : undefined });
    }
    return null;
};
