export default [
    {
        'alert-if-past': false,
        color: 'FF6455',
        'color-class': 'red',
        'rmks-state-class': 'encours',
        default: true,
        'display-previous': true,
        'export-image': 'export_remarkRegistered',
        image: 'icon_remarkRegistered',
        'icon-web': 'encours.png',
        label: 'Status_RemarkOpen',
        'label-and-date': 'Status_RemarkOpenAt',
        number: 0,
        value: 1,
        'plan-image': 'remarkRegistered',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        color: 'FF9731',
        'color-class': 'orange',
        'rmks-state-class': 'attente',
        default: true,
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkToCheck',
        'icon-web': 'attente_orange.png',
        label: 'Status_RemarkWait',
        'label-and-date': 'Status_RemarkWaitAt',
        number: 1,
        value: 2,
        'plan-image': 'remarkToCheck',
        state: 1,
        strike: false
    },
    {
        'alert-if-past': false,
        'close-label': 'Status_CloseAction',
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'valid',
        default: true,
        'display-previous': false,
        'export-image': 'export_remarkChecked',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Status_Close',
        'label-and-date': 'Status_RemarkCloseAt',
        number: 2,
        value: 4,
        'plan-image': 'remarkChecked',
        state: 2,
        strike: true
    },
    {
        'alert-if-past': true,
        color: 'FF6455',
        'color-class': 'red',
        'rmks-state-class': 'encours',
        default: true,
        'display-previous': true,
        'export-image': 'export_remarkLimitDate',
        image: 'icon_remarkChecked',
        'icon-web': 'attente_red.png',
        label: 'Status_RemarkLimitDate',
        'label-and-date': 'Status_RemarkLimitDateAt',
        number: 3,
        value: 8,
        'plan-image': 'remarkRegistered',
        state: 3,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'valid',
        default: true,
        'display-previous': false,
        'export-image': 'export_remarkChecked',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Status_RemarkQuitus',
        'label-and-date': 'Status_RemarkQuitusAt',
        number: 4,
        value: 16,
        'plan-image': 'remarkChecked',
        state: 2,
        strike: true
    },
    {
        'alert-if-past': false,
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'valid',
        default: true,
        'display-previous': true,
        'export-image': 'export_remarkChecked',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Status_RemarkForMemory',
        'label-and-date': 'PDF_Status_RemarkForMemoryAt',
        number: 5,
        value: 32,
        'plan-image': 'remarkChecked',
        state: 2,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '4f4f4f',
        'color-class': 'gray',
        'rmks-state-class': 'hidden',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkHidden',
        'icon-web': 'hidden.png',
        label: 'Status_RemarkHidden',
        'label-and-date': 'PDF_Status_RemarkHiddenAt',
        number: 6,
        value: 64,
        hidden: true,
        'plan-image': 'remarkToCheck',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        'close-label': 'Status_RemarkDisplayedAction',
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'display',
        'display-previous': false,
        'export-image': 'export_remarkChecked',
        image: 'icon_remarkDisplayed',
        'icon-web': 'displayed.png',
        label: 'Status_RemarkDisplayed',
        'label-and-date': 'PDF_Status_RemarkDisplayedAt',
        number: 7,
        value: 128,
        'plan-image': 'remarkChecked',
        state: 2,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '4f4f4f',
        'color-class': 'gray',
        'rmks-state-class': 'hidden',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkHidden',
        'icon-web': 'hidden.png',
        label: 'Status_RemarkNotValidated',
        'label-and-date': 'PDF_Status_RemarkNotValidatedAt',
        number: 8,
        value: 256,
        'plan-image': 'remarkToCheck',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'valid',
        'display-previous': false,
        'export-image': 'export_remarkChecked',
        'close-label': 'Status_RemarkValidatedAction',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Status_RemarkValidated',
        'label-and-date': 'PDF_Status_RemarkValidatedAt',
        number: 9,
        value: 512,
        'plan-image': 'remarkChecked',
        state: 2,
        strike: false
    },
    {
        'alert-if-past': false,
        color: 'FF9731',
        'color-class': 'orange',
        'rmks-state-class': 'attente',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkToCheck',
        'icon-web': 'valider.png',
        label: 'Status_RemarkToDo',
        'label-and-date': 'PDF_Status_RemarkTodo',
        number: 10,
        value: 1024,
        'plan-image': 'remarkToCheck',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        color: 'FF9731',
        'color-class': 'orange',
        'rmks-state-class': 'attente',
        'display-previous': true,
        'export-image': 'export_remarkRegistered',
        image: 'icon_remarkToCheck',
        'icon-web': 'attente_orange.png',
        label: 'Status_PartialCompliance',
        'label-and-date': 'Status_PartialComplianceAt',
        number: 11,
        value: 2048,
        'plan-image': 'remarkToCheck',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        color: 'FF6455',
        'color-class': 'red',
        'rmks-state-class': 'encours',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkRegistered',
        'icon-web': 'encours.png',
        label: 'Status_Noncompliance',
        'label-and-date': 'Status_NoncomplianceAt',
        number: 12,
        value: 4096,
        'plan-image': 'remarkRegistered',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        'close-label': 'Status_ControlledAndCompliantAction',
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'valid',
        'display-previous': false,
        'export-image': 'export_remarkChecked',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Status_ControlledAndCompliant',
        'label-and-date': 'Status_ControlledAndCompliantAt',
        number: 13,
        value: 8192,
        'plan-image': 'remarkChecked',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        'close-label': 'Status_RemarkArchivedAction',
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'valid',
        'display-previous': false,
        'export-image': 'export_remarkChecked',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Status_RemarkArchived',
        'label-and-date': 'PDF_Status_RemarkArchivedAt',
        number: 14,
        value: 16384,
        'plan-image': 'remarkChecked',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '38D553',
        'color-class': 'green',
        'rmks-state-class': 'valid',
        'display-previous': false,
        'export-image': 'export_remarkChecked',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Status_Unapplicable',
        'label-and-date': 'Status_UnapplicableAt',
        number: 15,
        value: 32768,
        'plan-image': 'remarkChecked',
        state: 2,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '4f4f4f',
        'color-class': 'gray',
        'rmks-state-class': 'hidden',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkHidden',
        'icon-web': 'hidden.png',
        label: 'Status_Pending',
        'label-and-date': 'Status_PendingAt',
        number: 16,
        value: 65536,
        'plan-image': 'remarkToCheck',
        state: 1,
        strike: false
    },
    {
        'alert-if-past': false,
        color: 'FF9731',
        'color-class': 'orange',
        'rmks-state-class': 'attente',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkToCheck',
        'icon-web': 'attente_orange.png',
        label: 'Status_RemarkWaitingValidation',
        'label-and-date': 'Status_RemarkWaitingValidationAt',
        number: 17,
        value: 131072,
        'plan-image': 'remarkToCheck',
        state: 1,
        strike: false,
        'ar-live': true
    },
    {
        'alert-if-past': false,
        color: '4f4f4f',
        'color-class': 'gray',
        'rmks-state-class': 'hidden',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkRegistered',
        'icon-web': 'valider.png',
        label: 'Point d’arrêt',
        'label-and-date': 'Point d’arrêt',
        number: 18,
        value: 262144,
        'plan-image': 'remarkToCheck',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        color: 'FF9731',
        'color-class': 'orange',
        'rmks-state-class': 'attente',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkToCheck',
        'icon-web': 'hidden.png',
        label: 'Ecart en cours à traiter',
        'label-and-date': 'Ecart en cours à traiter :',
        number: 19,
        value: 524288,
        'plan-image': 'remarkToCheck',
        state: 1,
        strike: false
    },
    {
        'alert-if-past': true,
        color: 'FF6455',
        'color-class': 'red',
        'rmks-state-class': 'encours',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkChecked',
        'icon-web': 'valider.png',
        label: 'Ecart majeur – Date limite',
        'label-and-date': 'Ecart majeur – Date limite :',
        number: 20,
        value: 1048576,
        'plan-image': 'remarkChecked',
        state: 0,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '4f4f4f',
        'color-class': 'gray',
        'rmks-state-class': 'hidden',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkHidden',
        'icon-web': 'hidden.png',
        label: 'Presence_Absent',
        'label-and-date': 'Presence_Absent',
        number: 21,
        value: 2097152,
        'plan-image': 'remarkToCheck',
        state: 1,
        strike: false
    },
    {
        'alert-if-past': false,
        color: 'FF6455',
        'color-class': 'red',
        'rmks-state-class': 'encours',
        default: true,
        'display-previous': true,
        'export-image': 'export_remarkLimitDate',
        image: 'icon_remarkChecked',
        'icon-web': 'attente_red.png',
        label: 'Presence_Present',
        'label-and-date': 'Presence_Present',
        number: 22,
        value: 4194304,
        'plan-image': 'remarkRegistered',
        state: 1,
        strike: false
    },
    {
        'alert-if-past': false,
        color: '5F6FFF',
        'color-class': 'orange',
        'rmks-state-class': 'attente',
        'display-previous': true,
        'export-image': 'export_remarkToChecked',
        image: 'icon_remarkToCheck',
        'icon-web': 'attente_orange.png',
        label: 'Status_RemarkObservationPending',
        'label-and-date': 'Status_RemarkObservationPendingAt',
        number: 23,
        value: 8388608,
        'plan-image': 'remarkToCheck',
        state: 1,
        strike: false,
        'ar-live': true,
        'ar-live-close-option': false
    }
];
