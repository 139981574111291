let Matrix = /** @class */ (() => {
    class Matrix {
        constructor(pdfWidth, pdfHeight, pdfLeft, pdfBottom, isRotated) {
            this.matrix = {};
            this.findMarkerZone = (posX, posY, offset = 0) => {
                const caseSize = Matrix.markWidth + Matrix.MARK_SPACE_MARGING;
                let colIdxStart = Math.floor(((posX - caseSize / 2) * (this.nbCol - 1)) / this.pdfWidth) - offset;
                let colIdxEnd = Math.floor(((posX + caseSize / 2) * (this.nbCol - 1)) / this.pdfWidth) + offset;
                let rowIdxStart = Math.floor(((posY - caseSize / 2) * (this.nbRow - 1)) / this.pdfHeight) - offset;
                let rowIdxEnd = Math.floor(((posY + caseSize / 2) * (this.nbRow - 1)) / this.pdfHeight) + offset;
                if (colIdxStart < 0) {
                    colIdxStart = 0;
                }
                if (rowIdxStart < 0) {
                    rowIdxStart = 0;
                }
                if (colIdxEnd >= this.nbCol) {
                    colIdxEnd = this.nbCol - 1;
                }
                if (rowIdxEnd >= this.nbRow) {
                    rowIdxEnd = this.nbRow - 1;
                }
                return { colIdxStart, colIdxEnd, rowIdxStart, rowIdxEnd };
            };
            this.placeMarkersOnMatrix = (posX, posY) => {
                const posXcropped = posX - this.pdfLeft; // Deal with cropbox offset
                const posYcropped = posY - this.pdfBottom; // Deal with cropbox offset
                const markerZone = this.findMarkerZone(posXcropped, posYcropped);
                const { colIdxStart, colIdxEnd, rowIdxStart, rowIdxEnd } = markerZone;
                let i = colIdxStart;
                while (i <= colIdxEnd) {
                    let j = rowIdxStart;
                    while (j <= rowIdxEnd) {
                        const coordonates = `${i};${j}`;
                        if (this.matrix[coordonates]) {
                            // If it's not locked, we lock it and return the current coordinate
                            this.matrix[coordonates]++;
                        }
                        else {
                            this.matrix[coordonates] = 1;
                        }
                        j++;
                    }
                    i++;
                }
            };
            this.findOtherPos = (posX, posY) => {
                let offset = 0;
                let newPosX = 0;
                let newPosY = 0;
                while (newPosX === 0) {
                    //var zone = this.findSquareZoneAroundMarker(posX, posY, offset);
                    const { colIdxStart, colIdxEnd, rowIdxStart, rowIdxEnd } = this.findMarkerZone(posX, posY, offset);
                    let i = colIdxStart;
                    while (i <= colIdxEnd) {
                        let j = rowIdxEnd;
                        while (j >= rowIdxStart) {
                            const coordonates = i + ';' + j;
                            if (!this.matrix[coordonates] || this.matrix[coordonates] === 1) {
                                newPosX = (i + 0.5) * (Matrix.markWidth + Matrix.MARK_SPACE_MARGING);
                                newPosY = (j + 0.5) * (Matrix.markWidth + Matrix.MARK_SPACE_MARGING);
                                this.matrix[coordonates] = 12;
                                break;
                            }
                            j--;
                        }
                        if (newPosX != 0) {
                            break;
                        }
                        i++;
                    }
                    offset++;
                }
                if (newPosX > this.pdfWidth) {
                    newPosX = this.pdfWidth;
                }
                if (newPosY > this.pdfHeight) {
                    newPosY = this.pdfHeight;
                }
                return { posX: newPosX, posY: newPosY };
            };
            this.getMarkerPosition = (posX, posY) => {
                let overlap = false;
                // Deal with cropbox offset
                let newPos = { posX: posX - this.pdfLeft, posY: posY - this.pdfBottom };
                const { colIdxStart, colIdxEnd, rowIdxStart, rowIdxEnd } = this.findMarkerZone(newPos.posX, newPos.posY);
                let i = colIdxStart;
                while (i <= colIdxEnd) {
                    let j = rowIdxStart;
                    while (j <= rowIdxEnd) {
                        const coordonates = i + ';' + j;
                        if (this.matrix[coordonates] != 1) {
                            overlap = true;
                            break;
                        }
                        j++;
                    }
                    if (overlap) {
                        break;
                    }
                    i++;
                }
                if (overlap) {
                    newPos = this.findOtherPos(posX, posY);
                }
                return {
                    newPos: {
                        posX: newPos.posX + this.pdfLeft,
                        posY: newPos.posY + this.pdfBottom
                    },
                    overlap
                };
            };
            this.pdfWidth = isRotated ? pdfHeight : pdfWidth;
            this.pdfHeight = isRotated ? pdfWidth : pdfHeight;
            this.pdfLeft = isRotated ? pdfBottom : pdfLeft;
            this.pdfBottom = isRotated ? pdfLeft : pdfBottom;
            this.nbCol = Math.ceil(this.pdfWidth / (Matrix.markWidth + Matrix.MARK_SPACE_MARGING));
            this.nbRow = Math.ceil(this.pdfHeight / (Matrix.markWidth + Matrix.MARK_SPACE_MARGING));
        }
    }
    Matrix.MARK_SPACE_MARGING = 3;
    Matrix.markWidth = 24;
    return Matrix;
})();
export default Matrix;
